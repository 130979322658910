let languages = {
	en: require('./en.json'),
	fr: require('./fr.json'),
	ar: require('./ar.json')
};

///This is for checking if there is unsuported languages keys
if (process.env.VUE_APP_ENVIRONMENT == 'local') {
	let defaultLanguageCode = null;
	Object.keys(languages).forEach((languageCode, index) => {
		if (index == 0) return (defaultLanguageCode = languageCode);

		let defaultLanguageKeys = Object.keys(languages[defaultLanguageCode]);
		let languageKeys = Object.keys(languages[languageCode]);

		let notInLanguage = defaultLanguageKeys.filter(
			el => !languageKeys.includes(el)
		);

		let notInDefaultLanguage = languageKeys.filter(
			el => !defaultLanguageKeys.includes(el)
		);

		let keysNotInLanguage = Object.keys(languages[defaultLanguageCode])
			.filter(key => notInLanguage.includes(key))
			.reduce((obj, key) => {
				obj[key] = languages[defaultLanguageCode][key];
				return obj;
			}, {});

		let keysNotInDefaultLanguage = Object.keys(languages[languageCode])
			.filter(key => notInDefaultLanguage.includes(key))
			.reduce((obj, key) => {
				obj[key] = languages[languageCode][key];
				return obj;
			}, {});

		if (notInLanguage.length > 0)
			console.log('Not In ', languageCode, keysNotInLanguage);
		if (notInDefaultLanguage.length > 0)
			console.log(
				'Not In ',
				defaultLanguageCode,
				keysNotInDefaultLanguage
			);
	});
}

export default languages;
