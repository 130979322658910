<template>
	<div id="app" class="relative">
		<loading ref="loading" />
		<transition name="page" mode="out-in">
			<router-view></router-view>
		</transition>
	</div>
</template>

<script>
import Loading from '@/components/Loading';
import interceptor from '@/plugins/axios';
import initEcho from '@/plugins/echo';

export default {
	name: 'App',
	data: () => ({
		loader: null
	}),
	components: {
		Loading
	},
	watch: {
		"$root.isOnline": function(newVal) {
			if (!newVal) this.$router.push({ name: "page-offline" })
			else this.$router.push({ name: "home" })
		},
		$route: function() {
			if (!this.$root.isOnline) this.$router.push({ name: "page-offline" })
		} 
	},
	metaInfo() {
		const appName = process.env.VUE_APP_NAME || '---';
		return {
			title: appName,
			titleTemplate: `%s · ${appName}`
		};
	},
	mounted: function() {
		this.loader = this.$refs.loading;
		interceptor(this);
		initEcho();
		if (
			['android', 'ios'].includes(this.$root.env.VUE_APP_MODE) &&
			window.plugins
		)
			this.initOnesignal();
	},
	methods: {
		initOnesignal: function() {
			// window.plugins.OneSignal.setLogLevel({
			// 	logLevel: 6,
			// 	visualLevel: 0
			// });

			window.plugins.OneSignal.startInit(
				process.env.VUE_APP_ONESIGNAL_NOTIFICATION_APP_ID
			)
				.handleNotificationOpened(() => {
					console.log('notification opened');
				})
				.iOSSettings({
					kOSSettingsKeyAutoPrompt: false,
					kOSSettingsKeyInAppLaunchURL: false
				})
				.inFocusDisplaying(
					window.plugins.OneSignal.OSInFocusDisplayOption.Notification
				)
				.endInit();

			window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(
				accepted => {
					console.log('User accepted notifications: ' + accepted);
				}
			);
		}
	}
};
</script>

<style></style>
