import VueCookies from 'vue-cookies';
import * as types from '../mutation-types';
import i18n from '@/plugins/i18n';
import { currencyFormat } from '@/tools/helpers';
// import { ApiGetCurrencies } from '@/tools/apiService';

let defaultCurrency = {
	value: 1,
	tag: 'MAD',
	isDefault: true
};

// state
export const state = {
	locale: VueCookies.get('locale') || 'fr',
	locales: {
		fr: {
			flag: require('@/assets/flags/fr.png'),
			label: 'Français'
		},
		en: {
			flag: require('@/assets/flags/en.png'),
			label: 'English'
		},
		ar: {
			flag: require('@/assets/flags/ar.png'),
			label: 'العربية'
		},
		it: {
			flag: require('@/assets/flags/it.png'),
			label: 'Italiana'
		}
	},
	currency: VueCookies.get('currency') || defaultCurrency,
	currencies: []
};

// getters
export const getters = {
	locale: state => state.locale,
	locales: state => state.locales,
	currencies: state => state.currencies,
	getCurrency: state =>
		typeof state.currency === 'object'
			? state.currency.tag
			: defaultCurrency.tag,
	getDefaultCurrency: () => defaultCurrency.tag,
	convertCurrency: state => (val, source = null) => {
		if (isNaN(val)) return 0;
		source = source ? source : defaultCurrency.tag;
		if (source == state.currency.tag) {
			return Number(val);
		} else {
			let currency = state.currencies.find(el => el.tag == source);
			return Number((val * currency.value) / state.currency.value);
		}
	},
	addCurrency: state => val => {
		return `${currencyFormat(val)} ${state.currency.tag}`;
	},
	formatedCurrency: (state, getters) => (val, source = null) => {
		let convertedTotal = getters.convertCurrency(val, source);
		return getters.addCurrency(convertedTotal);
	}
};

// mutations
export const mutations = {
	[types.SET_LOCALE](state, { locale }) {
		state.locale = locale;
		i18n.locale = locale;
	},
	[types.SET_CURRENCY](state, { currency }) {
		state.currency = currency;
	},
	[types.SET_CURRENCIES](state, { currencies }) {
		state.currencies = currencies;
	}
};

// actions
export const actions = {
	setLocale({ commit }, { locale }) {
		commit(types.SET_LOCALE, {
			locale
		});
		VueCookies.set('locale', locale, {
			expires: 365
		});
	},
	getCurrenciesHandler: function() {
		// return ApiGetCurrencies().then(({ data }) => {
		// 	commit(types.SET_CURRENCIES, {
		// 		currencies: data
		// 	});
		// 	if (!VueCookies.isKey('currency'))
		// 		commit(types.SET_CURRENCY, {
		// 			currency: data.find(el => el.isDefault)
		// 		});
		// });
	},
	currencyHandler: function({ commit }, { currency }) {
		commit(types.SET_CURRENCY, {
			currency
		});
		VueCookies.set('currency', currency, {
			expires: 365
		});
	}
};
