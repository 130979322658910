// import Cookies from 'js-cookie'
import * as types from '../mutation-types';
import { hourMinuteFromDate, formatDateForForm } from '@/tools/helpers';
import { ApiGetReductions } from '@/tools/apiService.js';

// state
export const state = {
	intervalReduction: {},
	deliveryHour: hourMinuteFromDate(new Date()),
	timeUpdated: false
};

// getters
export const getters = {
	intervalReduction: state => state.intervalReduction,
	deliveryHour: state => state.deliveryHour,
	timeUpdated: state => state.timeUpdated
};

// mutations
export const mutations = {
	[types.SET_REDUCTIONS](state, payload) {
		state.intervalReduction = payload;
	},
	[types.UPDATE_DELIVERY_HOUR](state, payload) {
		state.deliveryHour = payload;
	},
	[types.UPDATE_TIME_CHANGED](state) {
		state.timeUpdated = true;
	}
};

// actions
export const actions = {
	updateDeliveryHour: function({ dispatch, commit }, payload) {
		commit(types.UPDATE_TIME_CHANGED);
		commit(types.UPDATE_DELIVERY_HOUR, payload);
		dispatch('fetchReductions');
	},
	setDeliveryHourFromDate: function({ dispatch }, payload) {
		dispatch('updateDeliveryHour', hourMinuteFromDate(new Date(payload)));
	},
	fetchReductions: async function(
		{ dispatch, state, commit },
		deliveryHour = null
	) {
		let weekDay = await dispatch('stock/getWeekDay', {}, { root: true });

		let internalDeliveryHour = deliveryHour;
		if (internalDeliveryHour == null || !internalDeliveryHour.includes(':'))
			internalDeliveryHour = state.deliveryHour;

		let deliveryHourArray = internalDeliveryHour.toString().split(':');
		let dayDate = new Date();

		if (deliveryHourArray.length >= 1)
			dayDate.setHours(deliveryHourArray[0]);

		if (deliveryHourArray.length >= 2)
			dayDate.setMinutes(deliveryHourArray[1]);
		else dayDate.setMinutes(0);

		if (deliveryHourArray.length >= 3)
			dayDate.setSeconds(deliveryHourArray[2]);
		else dayDate.setSeconds(0);

		var currentDay = dayDate.getDay(); //6 //1 //-5
		let distance = weekDay - currentDay;
		if (distance < 0) distance = weekDay + 1;
		dayDate.setDate(dayDate.getDate() + distance);

		return ApiGetReductions(formatDateForForm(dayDate)).then(response => {
			commit(types.SET_REDUCTIONS, response.data.reduction);
		});
	}
};
