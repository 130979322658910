let apiUrl = process.env.VUE_APP_API_URL || '';
export default {
	TAGS: {
		deliveringPrice: 'delivering-price'
	},
	ACTIVE_TIMES: [
		'11:00',
		'11:30',
		'12:00',
		'12:30',
		'13:00',
		'13:30',
		'14:00',
		'14:30',
		'15:00',
		'15:30',
		'16:00',
		'16:30'
	],
	STORAGE_PATH: `${apiUrl}/storage/`,
	PUBLIC_PATH: '',
};
