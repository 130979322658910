// import Cookies from 'js-cookie'
import * as types from '../mutation-types';
import { weekDayFromDate } from '@/tools/helpers';
import { ApiGetProductStatus } from '@/tools/apiService.js';

// state
export const state = {
	status: [],
	weekday: weekDayFromDate(new Date())
};

// getters
export const getters = {
	status: state => state.status,
	weekday: state => state.weekday,
	isItToday: state => state.weekday == weekDayFromDate(new Date()),
	isExhausted: state => productList => {
		let isExhausted = false;
		let stockStatus = state.status;
		if (productList.length == 1) {
			let productStatus = stockStatus.find(
				el => el.productId == productList[0].id
			);
			if (!productStatus || productStatus.total == 0) isExhausted = true;
		} else {
			let categoryListId = [
				...new Set(productList.map(el => el.categoryId))
			];
			categoryListId.map(el => {
				let categoryProductList = productList.filter(
					subEl => subEl.categoryId == el
				);
				let decisionArray = categoryProductList
					.map(subEl => {
						let productStatus = stockStatus.find(
							el => el.productId == subEl.id
						);
						return productStatus ? true : false;
					})
					.filter(subEl => subEl === true);
				if (decisionArray.length == 0) isExhausted = true;
			});
		}
		return isExhausted;
	}
};

// mutations
export const mutations = {
	[types.SET_STOCK_STATUS](state, payloadStatus) {
		state.status = payloadStatus;
	},
	[types.UPDATE_WEEK_DAY](state, payload) {
		state.weekday = payload;
	}
};

// actions
export const actions = {
	getWeekDay: function({ state }) {
		return state.weekday;
	},
	updateWeekDay: function({ commit }, payload) {
		commit(types.UPDATE_WEEK_DAY, payload);
	},
	setWeekDayFromDate: function({ dispatch }, payload) {
		dispatch('updateWeekDay', weekDayFromDate(new Date(payload)));
	},
	updateProductStock: async function({ state }, payload) {
		state.status = state.status.map(el => {
			if (el.productId == payload.productId)
				el.total += -payload.quantity;
			return el;
		});
	},
	isProductExhausted: async function({ state }, productList) {
		let isExhausted = false;
		let stockStatus = state.status;
		if (productList.length == 1) {
			let productStatus = stockStatus.find(
				el => el.productId == productList[0].id
			);
			if (!productStatus || productStatus.total <= 0) isExhausted = true;
		} else {
			let categoryListId = [
				...new Set(productList.map(el => el.categoryId))
			];
			categoryListId.map(el => {
				let categoryProductList = productList.filter(
					subEl => subEl.categoryId == el
				);
				let decisionArray = categoryProductList
					.map(subEl => {
						let productStatus = stockStatus.find(
							el => el.productId == subEl.id
						);
						return productStatus && productStatus.total > 0
							? true
							: false;
					})
					.filter(subEl => subEl === true);
				if (decisionArray.length == 0) isExhausted = true;
			});
		}
		return isExhausted;
	},
	fetchStockStatus: function({ state, commit }, weekday = null) {
		let internalWeekDay = weekday;
		if (internalWeekDay == null) internalWeekDay = state.weekday;
		return ApiGetProductStatus(internalWeekDay)
			.then(response => {
				commit(types.SET_STOCK_STATUS, response.data.stockStatus);
			})
			.catch(() => {});
	}
};
