import axios from 'axios';

export default instance => {
	let sentRequests = [];

	// Request interceptor
	axios.interceptors.request.use(
		request => {
			if (!navigator.onLine) {
				instance.$root.isOnline = false;
				throw 'You are offline';
			}
			// spinning start to show
			// UPDATE: Add this code to show global loading indicator
			let data = request.data || {};

			if (!data.withoutLoader) {
				if (sentRequests.length == 0) instance.$root.isLoading = true;
				sentRequests.push({});
			}

			let token = instance.$store.getters['auth/token'];
			if (!token) token = instance.$store.getters['delivery.auth/token'];

			if (token) request.headers.common['Authorization'] = `${token}`;

			// request.headers.common['Access-Control-Allow-Origin'] = '*'
			// request.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
			// request.headers.common['Access-Control-Allow-Headers'] = '*'

			// let nowDate = new Date();
			// let formatedNowDate = nowDate.toISOString();

			// request.headers.common['User-Date'] = formatedNowDate;
			// request.headers.common['User-Currency'] =
			// 	instance.$store.getters['lang/getCurrency'];
			if (process.env.VUE_APP_MODE == 'ios') {
				const url = new URL(request.url);
				request.url = url.href;
			}

			return request;
		},
		() => {
			sentRequests.pop();
			if (sentRequests.length == 0) instance.$root.isLoading = false;
		}
	);

	// Response interceptor
	axios.interceptors.response.use(
		response => {
			let config = {};

			try {
				if (response.config.data) {
					config = JSON.parse(response.config.data);
				}
			} catch (error) {
				console.error("couldn't parse request data", error);
			}

			if (!config.withoutLoader) sentRequests.pop();
			if (sentRequests.length == 0) instance.$root.isLoading = false;

			try {
				if (config.method == 'post' || config.method == 'patch') {
					instance.$root.errors = {};
				}
			} catch (error) {
				console.error("couldn't check method ", error);
			}

			return response;
		},
		error => {
			console.log(
				'[ERROR] ',
				error.response ? error.response.data : error.response
			);

			let isMessageShowed = false;

			let config = {};
			try {
				if (error.config.data) config = JSON.parse(error.config.data);
			} catch (error) {
				console.error("couldn't parse request data");
			}

			if (!config.withoutLoader) sentRequests.pop();
			if (sentRequests.length == 0) instance.$root.isLoading = false;

			const response = error.response;
			if (!response) instance.$root.isOnline = false;
			const status = response ? response.status : null;
			const data = response ? response.data : null;

			if (
				!config.withoutLoader &&
				(status === 401 || (data && data.reason == 'NOT AUTHORIZED'))
			) {
				instance.$store.dispatch('auth/logout');
				instance.$router.push({
					name: 'login'
				});
				setTimeout(() => {
					location.reload();
				}, 100);
				instance.$notify.error({
					title: 'You are not authorized to view this page!',
					msg: 'Please login to continue',
					position: 'top center',
					iconPack: 'feather',
					icon: 'icon-alert-circle',
					color: 'danger'
				});
			}

			if (status === 422) {
				instance.$root.errors = data.errors ? data.errors : {};
			}

			if (
				!config.withoutLoader &&
				(status === 422 || status === 400 || status === 500) &&
				data
			) {
				let errorsObjects = data.errors;

				let errors =
					errorsObjects && errorsObjects.length > 0
						? Object.values(errorsObjects).flatMap(el => el)
						: [];

				if (data.message || (data && data.reason))
					errors.push({
						title:
							data.message ||
							(data && data.reason ? data.reason : '---'),
						detail: null
					});

				errors.forEach(el => {
					instance.$notify.error({
						title: el.title ? el.title : el,
						msg: el.detail,
						position: 'top center',
						iconPack: 'feather',
						icon: 'icon-alert-circle',
						color: 'danger'
					});
				});

				if (errors.length > 0) isMessageShowed = true;
			}

			if (!config.withoutLoader && status >= 500 && !isMessageShowed) {
				instance.$notify.error({
					title: instance.$t('alert.error-title'),
					msg: instance.$t('alert.error-text'),
					position: 'top center',
					iconPack: 'feather',
					icon: 'icon-alert-circle',
					color: 'danger'
				});
			}

			return Promise.reject(error);
		}
	);
};
