import * as types from '../mutation-types';

// state
export const state = {
	isRestaurantDialogOpen: false,
	selectedRestaurantId: null
};

// getters
export const getters = {
	isRestaurantDialogOpen: state => state.isRestaurantDialogOpen,
	selectedRestaurantId: state => state.selectedRestaurantId
};

// mutations
export const mutations = {
	[types.SET_RESTAURANT_DIALOG_STATE](state, dialogState) {
		state.isRestaurantDialogOpen = dialogState;
	},

	[types.SET_SELECTED_RESTAURANT_ID](state, restaurantId) {
		state.selectedRestaurantId = restaurantId;
	}
};

// actions
export const actions = {
	openRestaurantReview({ commit }, restaurantId) {
		commit(types.SET_RESTAURANT_DIALOG_STATE, true);
		commit(types.SET_SELECTED_RESTAURANT_ID, restaurantId);
	},
	closeRestaurantReview({ commit }) {
		commit(types.SET_RESTAURANT_DIALOG_STATE, false);
		commit(types.SET_SELECTED_RESTAURANT_ID, null);
	}
};
