import * as types from '../mutation-types';
import Vue from 'vue';

// state
export const state = {
	additionalInfo: localStorage.getItem('cart-additional-info')
		? JSON.parse(localStorage.getItem('cart-additional-info'))
		: {},
	inviteCards: localStorage.getItem('cart-invites')
		? JSON.parse(localStorage.getItem('cart-invites'))
		: [],
	command: localStorage.getItem('cart-command')
		? JSON.parse(localStorage.getItem('cart-command'))
		: {},
	commands: localStorage.getItem('cart-commands')
		? JSON.parse(localStorage.getItem('cart-commands'))
		: [],
	hash: localStorage.getItem('cart-hash')
		? JSON.parse(localStorage.getItem('cart-hash'))
		: {}
};

// getters
export const getters = {
	additionalInfo: state => state.additionalInfo,
	inviteCards: state => state.inviteCards,
	command: state => state.command,
	hash: state => state.hash,
	commands: state => state.commands,
	totalQuantity: state =>
		state.commands.reduce((result, el) => {
			return result + Number(el.quantity);
		}, 0),
	total: state =>
		state.commands
			.reduce((result, el) => {
				if (
					state.command.id &&
					!state.command.grouped &&
					el.clientId != null
				)
					return result + 0;
				else
					return (
						result +
						Number(el.quantity) * Number(el.price.promoPrice)
					);
			}, 0)
			.toFixed(2),
	priceQuantity: state => priceId =>
		state.commands
			.filter(el => {
				return el.clientId == null && el.price.id == priceId;
			})
			.reduce((result, el) => {
				return result + Number(el.quantity);
			}, 0),
	commandsByPrice: state => priceId =>
		state.commands.filter(el => {
			return el.clientId == null && el.price.id == priceId;
		})
};

// mutations
export const mutations = {
	[types.ADD_COMMAND_CONTAINER](state, payloadCommand) {
		state.command = payloadCommand;
		localStorage.setItem('cart-command', JSON.stringify(state.command));
	},

	[types.ADD_COMMAND](state, payloadCommand) {
		if (payloadCommand.commandIndex > -1) {
			state.commands[payloadCommand.commandIndex].quantity =
				payloadCommand.quantity;
		} else {
			let clonePayloadCommand = JSON.parse(
				JSON.stringify(payloadCommand)
			);
			clonePayloadCommand.price.products = null;
			clonePayloadCommand.products.map(el => {
				el.ingrediants = null;
				el.preparation = null;
				el.nurturingInfo = null;
				return el;
			});
			state.commands.push({
				clientId:
					clonePayloadCommand.price && clonePayloadCommand.price.pivot
						? clonePayloadCommand.price.pivot.clientId
						: null,
				price: clonePayloadCommand.price,
				products: clonePayloadCommand.products,
				quantity: clonePayloadCommand.quantity
			});
		}

		if (payloadCommand.commandIndex > -1 && payloadCommand.quantity <= 0)
			state.commands.splice(payloadCommand.commandIndex, 1);

		localStorage.setItem('cart-commands', JSON.stringify(state.commands));
	},

	[types.REMOVE_COMMAND](state, index) {
		state.commands.splice(index, 1);
	},

	[types.CANCEL_INVITATION](state) {
		state.hash = {};
		state.command = {};
		state.commands = state.commands.filter(el => !el.clientId);
		state.inviteCards = [];
		localStorage.setItem('cart-command', JSON.stringify(state.command));
		localStorage.setItem('cart-commands', JSON.stringify(state.commands));
		localStorage.setItem('cart-hash', JSON.stringify(state.hash));
		localStorage.setItem('cart-invites', JSON.stringify(state.inviteCards));
	},

	[types.REFRESH_COMMAND](state) {
		state.additionalInfo = {};
		state.hash = {};
		state.command = {};
		state.commands = [];
		state.inviteCards = [];
		localStorage.setItem('cart-command', JSON.stringify(state.command));
		localStorage.setItem('cart-commands', JSON.stringify(state.commands));
		localStorage.setItem('cart-hash', JSON.stringify(state.hash));
		localStorage.setItem('cart-invites', JSON.stringify(state.inviteCards));
		localStorage.setItem(
			'cart-additional-info',
			JSON.stringify(state.additionalInfo)
		);
	},

	[types.FAST_REFRESH_COMMAND](state) {
		// state.hash = {}
		state.commands = [];
		// state.inviteCards = []

		localStorage.setItem('cart-commands', JSON.stringify(state.commands));
		// localStorage.setItem('cart-hash', JSON.stringify(state.hash), { expires: 1 })
		// localStorage.setItem('cart-invites', JSON.stringify(state.inviteCards), { expires: 1 })
	},

	[types.SAVE_HASH](state, payload) {
		state.hash = payload;
		localStorage.setItem('cart-hash', JSON.stringify(state.hash));
	},

	[types.ADD_INVITE_CARDS](state, payload) {
		state.inviteCards = payload;
		localStorage.setItem('cart-invites', JSON.stringify(state.inviteCards));
	},

	[types.ADD_ADDITIONAL_INFO](state, payload) {
		state.additionalInfo = payload;
		localStorage.setItem(
			'cart-additional-info',
			JSON.stringify(state.additionalInfo)
		);
	}
};

// actions
export const actions = {
	saveAdditionalInfo({ commit }, payload) {
		commit(types.ADD_ADDITIONAL_INFO, payload);
	},
	saveHash({ commit }, payload) {
		commit(types.SAVE_HASH, payload);
	},
	addCommandContainer({ commit, dispatch }, command) {
		commit(types.ADD_COMMAND_CONTAINER, command);
		dispatch('checkCommandContainer');
	},
	checkCommandContainer({ state, dispatch }) {
		if (state.command && state.command.id) {
			dispatch(
				'reduction/setDeliveryHourFromDate',
				state.command.planifiedFor,
				{
					root: true
				}
			);
			dispatch('stock/setWeekDayFromDate', state.command.planifiedFor, {
				root: true
			});
		}
	},
	async addCommandToCart({ dispatch, commit }, command) {
		let priceIsExhausted = await dispatch(
			'stock/isProductExhausted',
			command.products,
			{
				root: true
			}
		);

		if (priceIsExhausted) {
			Vue.swal({
				type: 'warning',
				title: 'This product is out of stock',
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 3000
			});
			return false;
		} else {
			let payloadProductListId = command.products.map(subEl => subEl.id);
			let commandIndex = state.commands.findIndex(el => {
				let productListId = el.products.filter(subEl =>
					payloadProductListId.includes(subEl.id)
				);
				let firstCondition =
					el.price.id == command.price.id &&
					productListId.length == payloadProductListId.length;
				if (command.price.pivot != null)
					return (
						firstCondition &&
						el.clientId == command.price.pivot.clientId
					);
				else return firstCondition && el.clientId == null;
			});

			if (commandIndex > -1) {
				let quantityDiff =
					command.quantity - state.commands[commandIndex].quantity;
				command.products.map(el =>
					dispatch(
						'stock/updateProductStock',
						{
							productId: el.id,
							quantity: quantityDiff
						},
						{
							root: true
						}
					)
				);
			} else {
				command.products.map(el =>
					dispatch(
						'stock/updateProductStock',
						{
							productId: el.id,
							quantity: command.quantity
						},
						{
							root: true
						}
					)
				);
			}

			command.commandIndex = commandIndex;

			commit(types.ADD_COMMAND, command);

			return true;
		}
	},
	addCommandToCartNoCheck({ dispatch, commit }, command) {
		let payloadProductListId = command.products.map(subEl => subEl.id);
		let commandIndex = state.commands.findIndex(el => {
			let productListId = el.products.filter(subEl =>
				payloadProductListId.includes(subEl.id)
			);
			let firstCondition =
				el.price.id == command.price.id &&
				productListId.length == payloadProductListId.length;
			if (command.price.pivot != null)
				return (
					firstCondition &&
					el.clientId == command.price.pivot.clientId
				);
			else return firstCondition && el.clientId == null;
		});

		if (commandIndex > -1) {
			let quantityDiff =
				command.quantity - state.commands[commandIndex].quantity;
			command.products.map(el =>
				dispatch(
					'stock/updateProductStock',
					{
						productId: el.id,
						quantity: quantityDiff
					},
					{
						root: true
					}
				)
			);
		} else {
			command.products.map(el =>
				dispatch(
					'stock/updateProductStock',
					{
						productId: el.id,
						quantity: command.quantity
					},
					{
						root: true
					}
				)
			);
		}

		command.commandIndex = commandIndex;

		commit(types.ADD_COMMAND, command);
	},
	addInviteCard({ commit }, inviteCards) {
		commit(types.ADD_INVITE_CARDS, inviteCards);
	},
	removeCommandFromCart({ commit }, index) {
		commit(types.REMOVE_COMMAND, index);
	},
	cancelInvitation({ commit }) {
		commit(types.CANCEL_INVITATION);
	},
	refreshCart({ commit }) {
		commit(types.REFRESH_COMMAND);
	},
	fastRefreshCart({ commit }) {
		commit(types.FAST_REFRESH_COMMAND);
	}
};
