<template>
	<div>
		<t-modal v-model="isOpen">
			<div class="p-4">
				<header>
					<h3 class="dialog-title capitalize">{{ title }}</h3>
				</header>
				<div class="targets">
					<a
						:href="`fb-messenger://share/?link=${link}`"
						class="button"
					>
						<i class="mx-1 icofont-facebook-messenger"></i>
						<span>Facebook</span>
					</a>

					<!-- <a class="button">
						<i class="mx-1 icofont-twitter"></i>
						<span>Twitter</span>
					</a>

					<a class="button">
						<i class="mx-1 icofont-linkedin"></i>
						<span>LinkedIn</span>
					</a> -->

					<a
						:href="`whatsapp://send?text=${link}`"
						data-action="share/whatsapp/share"
						class="button"
					>
						<i class="mx-1 icofont-whatsapp"></i>
						<span>Whatsapp</span>
					</a>
				</div>
				<div class="link">
					<div class="pen-url">
						{{ link }}
					</div>
					<button @click="copyLink" class="copy-link">
						Copier le lien
					</button>
				</div>
			</div>
		</t-modal>
		<button
			class="btn text-white btn-lg btn-block rounded border-none bg-magenta py-3"
			@click="open"
		>
			<i class="icofont-share"></i> <slot></slot>
		</button>
	</div>
</template>

<script>
export default {
	props: ['title', 'message', 'link'],
	data: () => ({
		isOpen: false
	}),
	methods: {
		copyLink: function() {
			const el = document.createElement('textarea'); // Create a <textarea> element
			el.value = this.link; // Set its value to the string that you want copied
			el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
			el.style.position = 'absolute';
			el.style.left = '-9999px'; // Move outside the screen to make it invisible
			document.body.appendChild(el); // Append the <textarea> element to the HTML document
			const selected =
				document.getSelection().rangeCount > 0 // Check if there is any content selected previously
					? document.getSelection().getRangeAt(0) // Store selection if found
					: false; // Mark as false to know no selection existed before
			el.select(); // Select the <textarea> content
			document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
			document.body.removeChild(el); // Remove the <textarea> element
			if (selected) {
				// If a selection existed before copying
				document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
				document.getSelection().addRange(selected); // Restore the original selection
			}
			this.$notify.success({
				title: 'The link is copied',
				position: 'top center'
			});
		},
		open: function() {
			if (navigator.share) {
				navigator
					.share({
						title: `${this.message} click on this link ${this.link}`,
						text: `${this.message}`,
						url: this.link
					})
					.then(() => {
						console.log('Thanks for sharing!');
					})
					.catch(console.error);
			} else {
				this.isOpen = true;
			}
		},
		close: function() {
			this.isOpen = false;
		}
	}
};
</script>

<style scoped>
svg {
	width: 20px;
	height: 20px;
	margin-right: 7px;
}

button,
.button {
	align-items: center;
	justify-content: center;
	height: auto;
	padding-top: 8px;
	padding-bottom: 8px;
	color: #777;
	text-align: center;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.1;
	letter-spacing: 2px;
	text-transform: capitalize;
	text-decoration: none;
	white-space: nowrap;
	border-radius: 4px;
	border: 1px solid #ddd;
	cursor: pointer;
}

button:hover,
.button:hover {
	border-color: #cdd;
}

.share-button,
.copy-link {
	padding-left: 30px;
	padding-right: 30px;
}

header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.targets {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
	margin-bottom: 20px;
}

.close-button {
	background-color: transparent;
	border: none;
	padding: 0;
}

.close-button svg {
	margin-right: 0;
}

.link {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	border-radius: 4px;
	background-color: #eee;
}

.pen-url {
	margin-right: 15px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>
