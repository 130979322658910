import i18n from '@/plugins/i18n';

export const getMobileOperatingSystem = () => {
	var userAgent = navigator.userAgent || navigator.vendor || window.opera;
	// Windows Phone must come first because its UA also contains "Android"
	if (/windows phone/i.test(userAgent)) return 'windows_phone';
	if (/android/i.test(userAgent)) return 'android';
	// iOS detection from: http://stackoverflow.com/a/9039885/177710
	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return 'ios';
	return 'unknown';
};

export const stripHtml = html => html.replace(/<[^>]*>?/gm, '');

export const removeNewLines = txt => txt.replace(/(\r\n|\n|\r)/gm, '');

export const textTruncate = (str, length = 100, ending = '...') => {
	if (str && str.length > length)
		return str.substring(0, length - ending.length) + ending;
	else return str;
};

export const uniqueBy = (arr, objKey) => {
	let filtredArray = [...new Set(arr.map(el => el[objKey]))];
	return filtredArray.map(el => arr.find(subel => subel[objKey] == el));
};

export const capitalize = (str, lower = false) =>
	(lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match =>
		match.toUpperCase()
	);

export const formatNumber = num => {
	if (parseFloat(num) == num) return parseFloat(num).toFixed(2);
	else return null;
};

export const humanFileSize = bytes => {
	var thresh = 1024;
	if (Math.abs(bytes) < thresh) {
		return bytes + ' B';
	}
	var units = ['Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];
	var u = -1;
	do {
		bytes /= thresh;
		++u;
	} while (Math.abs(bytes) >= thresh && u < units.length - 1);
	return bytes.toFixed(1) + ' ' + units[u];
};

export const isColorLight = color => {
	// Variables for red, green, blue values
	var r, g, b, hsp;

	// Check the format of the color, HEX or RGB?
	if (color.match(/^rgb/)) {
		// If RGB --> store the red, green, blue values in separate variables
		color = color.match(
			/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
		);

		r = color[1];
		g = color[2];
		b = color[3];
	} else {
		// If hex --> Convert it to RGB: http://gist.github.com/983661
		color = +(
			'0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&')
		);

		r = color >> 16;
		g = (color >> 8) & 255;
		b = color & 255;
	}

	// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
	hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

	// Using the HSP value, determine whether the color is light or dark
	if (hsp > 127.5) {
		return true;
	} else {
		return false;
	}
};

export const extractQuery = criterias =>
	Object.keys(criterias).reduce(
		(result, key) => `${result}${key}=${criterias[key]}&`,
		''
	);

export const findNodesWith = (key, array) => {
	let filledArray = [];
	for (const node of array) {
		if (node.children && node.children.length > 0) {
			filledArray = [
				...filledArray,
				...findNodesWith(key, node.children)
			];
		} else if (key in node) filledArray.push(node);
	}
	return filledArray;
};

export const cloneObject = obj => JSON.parse(JSON.stringify(obj));

export const uuidv4 = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = (Math.random() * 16) | 0,
			v = c == 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

export const downloadObjectAsJson = (exportObj, exportName) => {
	var dataStr =
		'data:text/json;charset=utf-8,' +
		encodeURIComponent(JSON.stringify(exportObj));
	var downloadAnchorNode = document.createElement('a');
	downloadAnchorNode.setAttribute('href', dataStr);
	downloadAnchorNode.setAttribute('download', exportName + '.json');
	document.body.appendChild(downloadAnchorNode); // required for firefox
	downloadAnchorNode.click();
	downloadAnchorNode.remove();
};

export const getNestedProperty = (obj, prop) => {
	var parts = prop.split('.');

	if (Array.isArray(parts)) {
		var last = parts.pop(),
			l = parts.length,
			i = 1,
			current = parts[0];

		while ((obj = obj[current]) && i < l) {
			current = parts[i];
			i++;
		}

		if (obj) {
			return obj[last];
		}
	} else {
		throw 'parts is not valid array';
	}
};

export const keyify = (obj, prefix = '') =>
	Object.keys(obj).reduce((res, el) => {
		if (Array.isArray(obj[el])) {
			return res;
		} else if (typeof obj[el] === 'object' && obj[el] !== null) {
			return [...res, ...keyify(obj[el], prefix + el + '.')];
		} else {
			return [...res, prefix + el];
		}
	}, []);

export const getYearMonth = date => {
	let dateObj = date;
	if (typeof date != 'object') dateObj = new Date(dateObj);
	return `${dateObj.getUTCMonth()}/${dateObj.getUTCFullYear()}`;
};

export const getShortYear = date => {
	let dateObj = date;
	if (typeof date != 'object') dateObj = new Date(dateObj);
	return dateObj
		.getUTCFullYear()
		.toString()
		.substr(2, 2);
};

export const getDayOfYear = date => {
	let dateObj = date;
	if (typeof date != 'object') dateObj = new Date(dateObj);
	let start = new Date(dateObj.getUTCFullYear(), 0, 0);
	let diff = dateObj - start;
	let oneDay = 1000 * 60 * 60 * 24;
	let day = Math.floor(diff / oneDay);
	return day;
};

export const currencyFormat = (number = 0) => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2
	});

	return formatter
		.format(number)
		.replace('$', '')
		.replace(',', '')
		.replace(' ', '');
};

export const getMonthStartDate = date => {
	return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getWeekNumber = date => {
	// Copy date so don't modify original
	date = new Date(
		Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
	);
	// Set to nearest Thursday: current date + 4 - current day number
	// Make Sunday's day number 7
	date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
	// Get first day of year
	var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
	// Calculate full weeks to nearest Thursday
	return Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
};

export const formatDate = (date, withTime = false) => {
	if (parseFloat(date) != date) return date;

	let dateObj = date;
	if (typeof date != 'object' && dateObj != null) dateObj = new Date(dateObj);
	else return '---';

	let day = dateObj.getUTCDate();
	let monthIndex = dateObj.getUTCMonth();
	let year = dateObj.getUTCFullYear();

	let monthNames = [
		i18n.t('months.january'),
		i18n.t('months.february'),
		i18n.t('months.march'),
		i18n.t('months.april'),
		i18n.t('months.may'),
		i18n.t('months.june'),
		i18n.t('months.july'),
		i18n.t('months.august'),
		i18n.t('months.september'),
		i18n.t('months.october'),
		i18n.t('months.november'),
		i18n.t('months.december')
	];

	let dateString =
		String(day).padStart(2, '0') +
		' ' +
		monthNames[monthIndex] +
		' ' +
		year;

	if (!day) return ' -- ';
	if (withTime) {
		let hour = dateObj.getUTCHours();
		let minute = dateObj.getUTCMinutes();
		return `${dateString} ${String(hour).padStart(2, '0')}:${String(
			minute
		).padStart(2, '0')}`;
	}
	return dateString;
};

export const isNavigatorOnline = () => {
	return navigator.onLine ? true : false;
};

export const formatUnixDate = date => {
	let dateObj = date;
	if (typeof date != 'object') dateObj = new Date(dateObj);

	var currentTime = dateObj.getTime();
	var localOffset = -1 * dateObj.getTimezoneOffset() * 60000;

	return Math.round(new Date(currentTime + localOffset).getTime());

	// return parseInt(dateObj.getTime().toFixed(0));
	// return Number(Math.floor(dateObj.getTime()));
};

export const formatJsonDate = (date, withTime = true) => {
	let dateObj = date;
	if (typeof date != 'object') dateObj = new Date(dateObj);
	let dataString =
		dateObj.getUTCFullYear() +
		'-' +
		('0' + (dateObj.getUTCMonth() + 1)).slice(-2) +
		'-' +
		('0' + dateObj.getUTCDate()).slice(-2);
	if (withTime) return dataString;
	else
		return (
			dataString +
			'T' +
			('0' + dateObj.getUTCHours()).slice(-2) +
			':' +
			('0' + dateObj.getUTCMinutes()).slice(-2)
		);
};

export const getHour = date => {
	let dateObj = date;
	if (typeof date != 'object') dateObj = new Date(dateObj);
	return (
		('0' + dateObj.getHours()).slice(-2) +
		':' +
		('0' + dateObj.getMinutes()).slice(-2)
	);
};

export const formatTimeZoneJsonDate = date => {
	let dateObj = date;
	if (typeof date != 'object') dateObj = new Date(dateObj);
	let dataString =
		dateObj.getFullYear() +
		'-' +
		('0' + (dateObj.getMonth() + 1)).slice(-2) +
		'-' +
		('0' + dateObj.getDate()).slice(-2);
	return (
		dataString +
		'T' +
		('0' + dateObj.getHours()).slice(-2) +
		':' +
		('0' + dateObj.getMinutes()).slice(-2)
	);
};

export const extractChartSeries = (data, labelKey, valueKey) => {
	return data.map(el => ({
		x: el[labelKey],
		y: el[valueKey]
	}));
};

export const debounce = function(fn, delay) {
	var timeoutID = null;
	return function() {
		clearTimeout(timeoutID);
		var args = arguments;
		var that = this;
		timeoutID = setTimeout(function() {
			fn.apply(that, args);
		}, delay);
	};
};

export const getDomain = (url, subdomain) => {
	subdomain = subdomain || false;
	url = url.replace(/(https?:\/\/)?(www.)?/i, '');
	if (!subdomain) {
		url = url.split('.');
		url = url.slice(url.length - 2).join('.');
	}
	if (url.indexOf('/') !== -1) return url.split('/')[0];
	return url;
};

export const getDateDiffrences = date => {
	let dateObj = date;
	if (typeof date != 'object') {
		dateObj = new Date(dateObj);
	}

	let now = new Date();
	var diff = (dateObj - now) / 1000;
	diff = Math.floor(diff);

	var months = Math.floor(diff / (24 * 60 * 60 * 30));
	var days = Math.floor(diff / (24 * 60 * 60));
	var hours = Math.floor(diff / (60 * 60));

	if (months > 0) return i18n.tc('remains-months', months);
	else if (days > 0) return i18n.tc('remains-days', days);
	else if (hours > 0) return i18n.tc('remains-hours', hours);
	else return '';
};

export const dateFormater = function(date, tag = '-') {
	return date
		.toISOString()
		.slice(0, 10)
		.replace(/-/g, tag);
};

export const searchObject = (searchText, objects) => {
	if (searchText && searchText.length > 1) {
		return objects.filter(object => {
			return JSON.stringify(object)
				.toString()
				.toLowerCase()
				.includes(searchText);
		});
	} else {
		return objects;
	}
};

export const formatDateForForm = (date, withoutTime = false) => {
	let dateObj = date;
	if (typeof date != 'object') dateObj = new Date(dateObj);
	let dataString =
		dateObj.getFullYear() +
		'-' +
		('0' + (dateObj.getMonth() + 1)).slice(-2) +
		'-' +
		('0' + dateObj.getDate()).slice(-2);
	if (!withoutTime)
		return (
			dataString +
			'T' +
			('0' + dateObj.getHours()).slice(-2) +
			':' +
			('0' + dateObj.getMinutes()).slice(-2)
		);
	else return dataString;
};

export const weekDayFromDate = date => {
	return date.getDay();
};

export const yearFromDate = date => {
	return date.getFullYear();
};

export const hourFromDate = date => {
	return `${date.getHours()}:${date.getHours()}`;
};

export const hourMinuteFromDate = date => {
	let minutes = date.getMinutes();
	if (minutes >= 0 && minutes < 30) return `${date.getHours()}:00`;
	else return `${date.getHours()}:30`;
};

export const letterColor = string => {
	//alert(string.length);

	if (string.length === 0) {
		return 'hsl(0, 0, 100%)';
	} else {
		var sanitized = string.replace(/[^A-Za-z]/, '');
		var letters = sanitized.split('');

		//Determine the hue
		var hue = Math.floor(
			((letters[0].toLowerCase().charCodeAt() - 96) / 26) * 360
		);
		var ord = '';
		for (var i in letters) {
			ord = letters[i].charCodeAt();
			if ((ord >= 65 && ord <= 90) || (ord >= 97 && ord <= 122)) {
				hue += ord - 64;
			}
		}

		hue = hue % 360;

		//Determine the saturation
		var vowels = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u'];
		var count_cons = 0;

		//Count the consonants
		for (i in letters) {
			if (vowels.indexOf(letters[i]) == -1) {
				count_cons++;
			}
		}

		//Determine what percentage of the string is consonants and weight to 95% being fully saturated.
		var saturation = (count_cons / letters.length / 0.95) * 100;
		if (saturation > 100) saturation = 100;

		//Determine the luminosity
		var ascenders = ['t', 'd', 'b', 'l', 'f', 'h', 'k'];
		var descenders = ['q', 'y', 'p', 'g', 'j'];
		var luminosity = 50;
		var increment = (1 / letters.length) * 50;

		for (i in letters) {
			if (ascenders.indexOf(letters[i]) != -1) {
				luminosity += increment;
			} else if (descenders.indexOf(letters[i]) != -1) {
				luminosity -= increment * 2;
			}
		}
		if (luminosity > 100) luminosity = 100;

		return 'hsl(' + hue + ', ' + saturation + '%, ' + luminosity + '%)';
	}
};

export const timeSince = date => {
	var seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

	var interval = seconds / 31536000;

	if (interval > 1) {
		return Math.floor(interval) + ' years'; //années
	}
	interval = seconds / 2592000;
	if (interval > 1) {
		return Math.floor(interval) + ' months'; //mois
	}
	interval = seconds / 86400;
	if (interval > 1) {
		return Math.floor(interval) + ' days'; //jours
	}
	interval = seconds / 3600;
	if (interval > 1) {
		return Math.floor(interval) + ' hours'; //heures
	}
	interval = seconds / 60;
	if (interval > 1) {
		return Math.floor(interval) + ' minutes'; //minutes
	}
	return Math.floor(seconds) + ' seconds'; //secondes
};
