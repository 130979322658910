function page(path) {
	return () =>
		import(/* webpackChunkName: '' */ `@/views/${path}`).then(
			m => m.default || m
		);
}

export default [
	{
		path: '/auth',
		component: () => import('@/layouts/Auth.vue'),
		children: [
			{
				path: ':provider/callback',
				component: page('auth/callback.vue'),
				meta: {
					breadCrumb: 'Redirction page'
				}
			},
			{
				path: 'login',
				name: 'auth-login',
				component: page('auth/login.vue'),
				meta: {
					breadCrumb: 'Login'
				}
			},
			{
				path: 'signup',
				name: 'auth-signup',
				component: page('auth/signup.vue'),
				meta: {
					breadCrumb: 'Register'
				}
			}
		]
	},
	{
		path: '/404',
		name: 'page-404',
		component: page('errors/404.vue'),
		meta: {
			breadCrumb: 'Error Page 404'
		}
	},
	{
		path: '/offline',
		name: 'page-offline',
		component: page('errors/offline.vue'),
		meta: {
			breadCrumb: 'Error Page Offline'
		}
	},
	{
		path: '/',
		component: () => import('@/layouts/Home.vue'),
		children: [
			{
				path: '/',
				name: 'home',
				component: page('home/index.vue'),
				meta: {
					breadCrumb: 'Home'
				}
			}
		]
	},
	{
		path: '/profile',
		component: () => import('@/layouts/Profile.vue'),
		children: [
			{
				path: 'commands',
				name: 'user-commands',
				component: page('profile/index.vue'),
				meta: {
					breadCrumb: 'Your orders'
				}
			},
			{
				path: 'commands',
				name: 'user-my-commands',
				component: page('profile/index.vue'),
				meta: {
					breadCrumb: 'Your current orders'
				}
			},
			{
				path: 'historique',
				name: 'user-history',
				component: page('profile/index.vue'),
				meta: {
					breadCrumb: 'Your archive'
				}
			},
			{
				path: 'favorites',
				name: 'user-favorites',
				component: page('profile/favorites.vue'),
				meta: {
					breadCrumb: 'Your favorites'
				}
			}
		]
	},
	{
		path: '/navigation',
		component: () => import('@/layouts/Main.vue'),
		children: [
			{
				path: '/table/:tableId',
				name: 'table-show',
				component: page('tables/index.vue'),
				meta: {
					breadCrumb: 'Table booking'
				}
			},
			{
				path: '/restaurants/:restaurantId',
				name: 'restaurant-show',
				component: page('restaurants/show.vue'),
				meta: {
					breadCrumb: 'Restaurant'
				}
			},
			{
				path: '/restaurants/:restaurantId/review',
				name: 'user-restaurant-review',
				component: page('restaurants/review.vue'),
				meta: {
					breadCrumb: 'Your Review'
				}
			},
			{
				path: 'restaurants',
				name: 'restaurants',
				component: page('restaurants/index.vue'),
				meta: {
					breadCrumb: 'Restaurants'
				}
			},
			{
				path: '/restaurants/:restaurantId/payment',
				name: 'payment',
				component: page('restaurants/payment.vue'),
				meta: {
					breadCrumb: 'Payment'
				}
			},
			{
				path: 'qr-scanner',
				name: 'qrscanner',
				component: page('qrscanner/index.vue'),
				meta: {
					breadCrumb: 'Scan the Q/R code'
				}
			},
			{
				path: 'privacy',
				name: 'privacy',
				component: page('privacy/index.vue'),
				meta: {
					breadCrumb: 'Privacy policy'
				}
			}
		]
	},
	{
		path: '*',
		redirect: {
			name: 'page-404'
		}
	}
];
