import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSocialauth from 'vue-social-auth';

Vue.use(VueAxios, axios);

Vue.use(VueSocialauth, {
	providers: {
		google: {
			clientId:
				'38113790426-8v9s1hdanfh0ahsuv4nedlc1b2eldvtd.apps.googleusercontent.com',
			redirectUri:
				'https://backend-adf.developer.utopie.ma/auth/callback/google',
			name: 'oauth:google'
		},
		facebook: {
			clientId: '139982534804194',
			redirectUri:
				'https://backend-adf.developer.utopie.ma/auth/callback/facebook',
			name: 'oauth:facebook'
		}
	}
});
