// login.js
export const SAVE_TOKEN = '[AUTH] SAVE_TOKEN';
export const FETCH_USER_SUCCESS = '[AUTH] FETCH_USER_SUCCESS';
export const FETCH_ROLE_SUCCESS = '[AUTH] FETCH_ROLE_SUCCESS';
export const FETCH_USER_FAILURE = '[AUTH] FETCH_USER_FAILURE';
export const LOGOUT = '[AUTH] LOGOUT';
export const UPDATE_USER = '[AUTH] UPDATE_USER';

// lang.js
export const SET_LOCALE = '[LANG] SET_LOCALE';
export const SET_CURRENCY = '[LANG] SET_CURRENCY';
export const SET_CURRENCIES = '[LANG] SET_CURRENCIES';

// users.js
export const SET_USERS = '[STORES] SET_USERS';
export const SET_ROLES = '[STORES] SET_ROLES';

// cart.js
export const SAVE_PRICE_LIST = 'SAVE_PRICE_LIST';
export const ADD_COMMAND_CONTAINER = 'ADD_COMMAND_CONTAINER';
export const ADD_COMMAND = 'ADD_COMMAND';
export const REMOVE_COMMAND = 'REMOVE_COMMAND';
export const REFRESH_COMMAND = 'REFRESH_COMMAND';
export const SAVE_HASH = 'SAVE_HASH';
export const ADD_INVITE_CARDS = 'ADD_INVITE_CARDS';
export const FAST_REFRESH_COMMAND = 'FAST_REFRESH_COMMAND';
export const CANCEL_INVITATION = 'CANCEL_INVITATION';
export const ADD_TABLE = 'ADD_TABLE';
export const ADD_ADDITIONAL_INFO = 'ADD_ADDITIONAL_INFO';

// stock.js
export const SET_STOCK_STATUS = 'SET_STOCK_STATUS';
export const UPDATE_WEEK_DAY = 'UPDATE_WEEK_DAY';

// reduction.js
export const SET_REDUCTIONS = 'SET_REDUCTIONS';
export const UPDATE_DELIVERY_HOUR = 'UPDATE_DELIVERY_HOUR';
export const UPDATE_TIME_CHANGED = 'UPDATE_TIME_CHANGED';

// notification.js
export const SET_RESTAURANT_DIALOG_STATE = 'SET_RESTAURANT_DIALOG_STATE';
export const SET_SELECTED_RESTAURANT_ID = 'SET_SELECTED_RESTAURANT_ID';
