<template>
	<div class="container" style="padding: 4rem">
		<div class="flex align-items-strech hv-100">
			<div
				class="flex-1 shadow text-center p-4 mx-8"
				style="background-color: white;border-radius: 1rem;padding: 2rem 1rem;"
			>
				<div
					class="flex align-items-center pb-6"
					style="border-bottom: 1px solid #f6f6f6;"
				>
					<img
						class="logo m-auto w-25 rounded-4xl shadow"
						src="/demo/logo.png"
					/>
					<h2 class="flex-1 mb-3 text-left mx-4">
						Chill Order - Easy way
						<small
							class="btn-danger h6 text-white rounded px-2 py-1"
							>v{{ version }}</small
						>
					</h2>
				</div>
				<br />
				<img
					class="qrcode m-auto"
					src="/demo/qrcode.jpg"
					style="margin: 0rem -0.7rem;"
				/>
				<p class="text-danger small mb-5">
					Scan to View on Your Mobile Device
				</p>
				<a
					class="btn btn-lg btn-primary shadow mx-2"
					href="https://apps.apple.com/us/developer/utopie/id1230101305?l=fr"
				>
					<strong>IOS</strong> Download
				</a>
				<a
					class="btn btn-lg btn-success shadow mx-2"
					href="https://play.google.com/store/apps/developer?id=UTOPIE+Maroc"
				>
					<strong>ANDROID</strong> Download
				</a>
				<p class="my-3">
					<span class="bg-warning small rounded px-2 py-1 text-dark">
						Direct links to the store
					</span>
				</p>
			</div>
			<div class="text-center">
				<div class="phone-screen relative">
					<img
						:src="image"
						ref="screen"
						class="select-none pointer-events-none relative z-10 m-auto"
						style="z-index: 1"
					/>
					<div
						style="top: 50%;left: 50%;transform: translate(-50%, -50%);"
						class="absolute f-r"
					>
						<iframe
							name="preview"
							class="rounded-4xl p-2"
							ref="iframe"
							@load="$root.renderChildren"
						></iframe>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		image: require('@/assets/images/frame.png')
	}),
	mounted: function() {
		setTimeout(() => {
			this.$refs.iframe.style.height = `${this.$refs.screen.offsetHeight}px`;
			this.$refs.iframe.style.width = `${this.$refs.screen.offsetWidth}px`;
		}, 1000);
	},
	computed: {
		version: function() {
			return process.env.VUE_APP_VERSION;
		}
	}
};
</script>

<style></style>
