import Vue from 'vue';
import App from './App.vue';
import Frame from './Frame.vue';
import router from '@/router';
import store from './store';
import i18n from '@/plugins/i18n';

// Plugings
import '@/plugins';

// Globally Registered Components
import './globalComponents.js';

// Tailwind
import './assets/css/tailwind.css';

// Styles: SCSS
import './assets/scss/main.scss';

window.io = require('socket.io-client');

Vue.prototype.$slugify = require('slugify');

// Constants
import Constants from './tools/constants';
Vue.prototype.$constants = Constants;

Vue.config.productionTip = false;

var browser = null;

const initFrame = () => {
	new Vue({
		name: 'IFrame',
		data: () => ({
			iApp: null
		}),
		mounted: function() {
			const iframe = this.$el.getElementsByTagName('iframe')[0];
			const head = iframe.contentDocument.head;
			// Select the node that will be observed for mutations
			const targetNode = document.getElementsByTagName('head')[0];
			// Options for the observer (which mutations to observe)
			const config = { attributes: true, childList: true, subtree: true };
			// Callback function to execute when mutations are observed
			const callback = function(mutationsList) {
				// Use traditional 'for loops' for IE 11
				for (const mutation of mutationsList) {
					if (mutation.type === 'childList') {
						console.log('changed');
						while (head.firstChild)
							head.removeChild(head.lastChild);
						let outerHead = window.parent.document.head;
						outerHead.childNodes.forEach(function(e) {
							let cln = e.cloneNode(true);
							head.appendChild(cln);
						});
					} else if (mutation.type === 'attributes') {
						console.log(
							'The ' +
								mutation.attributeName +
								' attribute was modified.'
						);
					}
				}
			};
			// Create an observer instance linked to the callback function
			const observer = new MutationObserver(callback);
			// Start observing the target node for configured mutations
			observer.observe(targetNode, config);
		},
		methods: {
			renderChildren: function() {
				const body = this.$el.getElementsByTagName('iframe')[0]
					.contentDocument.body;
				document
					.querySelectorAll('body > script')
					.forEach(el => body.appendChild(el.cloneNode(true)));
				document
					.querySelectorAll('body > .vuejs-notify-container')
					.forEach(el => body.appendChild(el.cloneNode(true)));
				const el = document.createElement('div');
				body.appendChild(el);
				this.iApp = init(el, true);
			}
		},
		render: h => h(Frame)
	}).$mount('#app');
};

const init = (element = '#app', isInsideIframe = false) => {
	return new Vue({
		i18n,
		router,
		store,
		data: () => ({
			errors: {},
			cities: [],
			tags: [],
			countries: [],
			history: [],
			notifications: [],
			env: process.env,
			echo: null,
			isOnline: true,
			isInsideIframe: isInsideIframe,
			isLoading: false
		}),
		render: h => h(App)
	}).$mount(element);
};

if (process.env.VUE_APP_PUBLIC_PATH == '') {
	const cordovaScript = document.createElement('script');
	cordovaScript.setAttribute('src', 'cordova.js');
	document.head.appendChild(cordovaScript);

	const toastScript = document.createElement('script');
	toastScript.setAttribute('src', 'js/Toast.js');
	document.head.appendChild(toastScript);

	setTimeout(function() {
		navigator.splashscreen.hide();
	}, 2000);
}

document.addEventListener('deviceready', () => {
	if (window.cordova && window.cordova.InAppBrowser) {
		// window.open = window.cordova.InAppBrowser.open;
		window.open = (url, name, features) => {
			browser = window.cordova.InAppBrowser.open(url, name, features);
			browser.addEventListener('exit', function(event) {
				console.log('exiting the browser', event);
				browser.closed = true;
			});
			browser.addEventListener('loadstart', function(event) {
				console.log('starting loading', event);
				if (event.url.startsWith(process.env.VUE_APP_URL)) {
					console.log('closing the browser');
					const url = new URL(event.url);
					const params = new URLSearchParams(url.search);
					const token = params.get('token');
					localStorage.setItem('token', token);
					browser.close();
					browser.closed = true;
				}
			});
			browser.closed = false;
			return browser;
		};
	}

	if (
		window.innerWidth <= 1000 ||
		['android', 'ios'].includes(process.env.VUE_APP_MODE)
	)
		init();
	else initFrame();
});

if (
	!['android', 'ios'].includes(process.env.VUE_APP_MODE) &&
	(typeof window.cordova == 'undefined' || !window.cordova)
) {
	console.log('cordova undefined ...');
	document.dispatchEvent(new CustomEvent('deviceready', {}));
}
