import store from '@/store';

export default (to, from, next) => {
	if (store.getters['auth/check']) {
		if (from.name !== 'home') {
			next({ name: 'home', query: to.query });
		} else {
			next({ query: to.query });
		}
	} else {
		next();
	}
};
