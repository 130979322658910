import axios from 'axios';
import store from '@/store';
import { extractQuery } from '@/tools/helpers';

let apiUrl = process.env.VUE_APP_API_URL || '';
let apiBase = `${apiUrl}/api`;

///----- Authentication
export const ApiAuthUser = data =>
	axios({
		method: 'POST',
		url: `${apiBase}/auth/login`,
		data
	});

///----- Forget Authentication
export const ApiAuthForgotEmail = data =>
	axios({
		method: 'POST',
		url: `${apiBase}/auth/forgot`,
		data
	});

///----- Authenticated the user by command
export const ApiAuthUserByCommand = commandId =>
	axios({
		method: 'POST',
		url: `${apiBase}/auth/login?commandId=${commandId}`
	});

///----- get the Authenticated user
export const ApiGetAuthUser = () =>
	axios({
		method: 'GET',
		url: `${apiBase}/app/user`
	});

///----- get the product status
export const ApiGetProductStatus = date =>
	axios({
		method: 'GET',
		url: `${apiBase}/app/stock_status?date=${date}`
	});

///----- get the reductions
export const ApiGetReductions = date =>
	axios({
		method: 'GET',
		url: `${apiBase}/app/reductions?date=${date}`
	});

///----- get the Authenticated delivery user
export const ApiGetDeliveryAuthUser = () =>
	axios({
		method: 'GET',
		url: `${apiBase}/app/delivery/user`
	});

///----- Product API
export const ApiGetHomeProducts = (date = null) =>
	axios({
		method: 'GET',
		url: `${apiBase}/home/products/${
			!store.getters['auth/token'] ? 'guests' : ''
		}?date=${date}`
	});

///----- Products API
export const ApiGetProducts = ({
	criterias = [],
	withRelations = [],
	offset = '',
	limit = '',
	columns = []
}) => {
	let query = extractQuery(criterias);
	let withQuery = withRelations.join(',');
	let columnsQuery = columns.join(',');
	return axios({
		method: 'GET',
		url: `${apiBase}/products?${query}&with=${withQuery}&offset=${offset}&limit=${limit}&columns=${columnsQuery}`
	});
};

///----- Checkout recomanded Product API
export const ApiGetRecomandedProducts = () =>
	axios({
		method: 'GET',
		url: `${apiBase}/checkout/products/simple/${
			!store.getters['auth/token'] ? 'guests' : ''
		}`
	});

///----- Checkout recomanded Product API
export const ApiGetPromoReduction = promoCode =>
	axios({
		method: 'GET',
		url: `${apiBase}/checkout/promo/${promoCode}/${
			!store.getters['auth/token'] ? 'guests' : ''
		}`
	});

///----- register and authenticate client
export const ApiRegisterClient = data =>
	axios({
		method: 'POST',
		url: `${apiBase}/checkout/client/register`,
		data
	});

///----- add commands
export const ApiCartSubmit = data =>
	axios({
		method: 'POST',
		url: `${apiBase}/checkout/cart/submit`,
		data
	});

///---- get command
export const ApiGetCommand = commandId =>
	axios({
		method: 'GET',
		url: `${apiBase}/profile/command/${commandId}`
	});

///----- get user addresses
export const ApiProfileAdresses = () =>
	axios({
		method: 'GET',
		url: `${apiBase}/profile/address`
	});

///----- delete user address
export const ApiDeleteAdress = addressId =>
	axios({
		method: 'DELETE',
		url: `${apiBase}/profile/address/${addressId}`
	});

///----- delete user address
export const ApiProfileCommands = (isActive = false, isHistory = false) =>
	axios({
		method: 'GET',
		url: `${apiBase}/profile/commands?isActive=${
			isActive ? 1 : 0
		}&isHistory=${isHistory ? 1 : 0}`
	});

///----- make product favorit
export const ApiFavoritPrice = priceId =>
	axios({
		method: 'POST',
		url: `${apiBase}/app/price/${priceId}`
	});

///----- get products favorit
export const ApiProfileFavoritPrice = () =>
	axios({
		method: 'GET',
		url: `${apiBase}/profile/favorite/products`
	});

///----- get products favorit
export const ApiInviteCollegues = data =>
	axios({
		method: 'POST',
		url: `${apiBase}/checkout/invite/collegues`,
		data
	});

///----- update user
export const ApiUpdateUser = data =>
	axios({
		method: 'PATCH',
		url: `${apiBase}/profile/user/update`,
		data
	});

///----- update user
export const ApiSetUserOnline = (userId, data) =>
	axios({
		method: 'POST',
		url: `${apiBase}/app/user/${userId}/online`,
		data
	});

///----- get restaurants
export const ApiGetRestaurants = () =>
	axios({
		method: 'GET',
		url: `${apiBase}/restaurants`
	});

///----- add restaurant review
export const ApiAddRestaurantReview = (restaurantId, data) =>
	axios({
		method: 'POST',
		url: `${apiBase}/restaurants/${restaurantId}/review`,
		data
	});

///----- get restaurant tables
export const ApiGetRestaurantTables = restaurantId =>
	axios({
		method: 'GET',
		url: `${apiBase}/restaurants/${restaurantId}/tables`
	});

///----- get restaurant table
export const ApiGetTable = qrCode =>
	axios({
		method: 'GET',
		url: `${apiBase}/restaurants/table/${qrCode}`
	});
///----- get restaurant countries
export const ApiGetCountries = () =>
	axios({
		method: 'GET',
		url: `${apiBase}/restaurants/countries`
	});
///----- get restaurant cities
export const ApiGetCities = () =>
	axios({
		method: 'GET',
		url: `${apiBase}/restaurants/cities`
	});
///----- get restaurant tags
export const ApiGetTags = () =>
	axios({
		method: 'GET',
		url: `${apiBase}/restaurants/tags`
	});

///----- get restaurant
export const ApiGetRestaurant = restaurantId =>
	axios({
		method: 'GET',
		url: `${apiBase}/restaurants/${restaurantId}`
	});

///----- get Command by hash
export const ApiCommandByHash = hash =>
	axios({
		method: 'GET',
		url: `${apiBase}/app/command/hash?hash=${hash}`
	});

///---- get command for the deliver man
export const ApiGetDeliverManCommands = tag =>
	axios({
		method: 'GET',
		url: `${apiBase}/delivery/commands?tag=${tag}`
	});

///---- post command address
export const ApiPostDeliverManPosition = (commandId, data) => {
	data.withoutLoader = true;
	return axios({
		method: 'POST',
		url: `${apiBase}/delivery/command/${commandId}`,
		data
	});
};

///---- post command address
export const ApiPostCommandReady = commandId =>
	axios({
		method: 'POST',
		url: `${apiBase}/delivery/command/${commandId}/ready`
	});

///---- post command address
export const ApiPostCommandDelivered = commandId =>
	axios({
		method: 'POST',
		url: `${apiBase}/delivery/command/${commandId}/delivered`
	});

///---- post command paying
export const ApiPostCommandPayed = commandId =>
	axios({
		method: 'POST',
		url: `${apiBase}/delivery/command/${commandId}/payed`
	});
///---- post command validating
export const ApiPostCommandValidated = commandId =>
	axios({
		method: 'POST',
		url: `${apiBase}/delivery/command/${commandId}/validated`
	});

///---- get delivery man position
export const ApiGetDeliveryManPosition = commandId =>
	axios({
		method: 'GET',
		url: `${apiBase}/delivery/command/${commandId}/position`,
		data: {
			withoutLoader: true
		}
	});

///---- get delivery man position
export const ApiGetInitData = () =>
	axios({
		method: 'GET',
		url: `${apiBase}/app/init_data`
	});

///---- get delivery man position
export const ApiPostContact = data =>
	axios({
		method: 'POST',
		url: `${apiBase}/app/contact`,
		data
	});

///---- cancel command
export const ApiCancelCommand = commandId =>
	axios({
		method: 'POST',
		url: `${apiBase}/checkout/command/${commandId}/cancel`
	});

///---- cancel command
export const ApiUpdateCommand = (commandId, data) =>
	axios({
		method: 'PATCH',
		url: `${apiBase}/checkout/command/${commandId}`,
		data
	});

///---- get notifications
export const ApiGetNotifications = ({ seen = false }) =>
	axios({
		method: 'GET',
		url: `${apiBase}/profile/notifications?seen=${
			seen === null ? '' : seen
		}`,
		data: { withoutLoader: true }
	});
///---- set notifications seen
export const ApiUpdateNotifications = data =>
	axios({
		method: 'PATCH',
		url: `${apiBase}/profile/notifications`,
		data: {
			...data,
			withoutLoader: true
		}
	});

///---- get messages
export const ApiGetMessages = (subjects, withoutLoader = false) =>
	axios({
		method: 'GET',
		url: `${apiBase}/profile/messages?subjects=${subjects}`,
		data: { withoutLoader }
	});

///---- add message
export const ApiAddMessage = data =>
	axios({
		method: 'POST',
		url: `${apiBase}/profile/messages`,
		data
	});
///---- add anonyme message
export const ApiAddAnonymeMessage = data =>
	axios({
		method: 'POST',
		url: `${apiBase}/profile/messages/anonyme`,
		data
	});

///---- get contacts
export const ApiGetContacts = () =>
	axios({
		method: 'GET',
		url: `${apiBase}/profile/contacts`
	});

export const ApiSearchContacts = keyword =>
	axios({
		method: 'GET',
		url: `${apiBase}/profile/contacts/search?keyword=${keyword}`
	});

///---- add contact
export const AddContact = data =>
	axios({
		method: 'POST',
		url: `${apiBase}/profile/contact`,
		data
	});
