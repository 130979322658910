import axios from 'axios';
import VueCookies from 'vue-cookies';
import * as types from '../mutation-types';

import { ApiGetAuthUser } from '@/tools/apiService.js';

let kc = null
let token = localStorage.getItem('token');
VueCookies.set('token', token, '1d');
if (process.env.VUE_APP_MODE == 'ios' && window.plugins) {
	kc = new window.plugins.Keychain()
	kc.getForKey((val) => token = val, console.error, 'token', 'ios')
}

// state
export const state = {
	user: null,
	role: {},
	token
};

// getters
export const getters = {
	user: state => state.user,
	role: state => state.role || {},
	token: state => state.token,
	check: state => state.user !== null
};

// mutations
export const mutations = {
	[types.SAVE_TOKEN](state, { token, remember }) {
		state.token = token;
		if (!kc) {
			localStorage.setItem('token', token);
			VueCookies.set('token', token, remember ? '30d' : '1d');
		} else {
			kc.setForKey((val) => token = val, console.error, 'token', 'ios', token)
		}
	},

	[types.FETCH_USER_SUCCESS](state, { user }) {
		state.user = user;
	},

	[types.FETCH_ROLE_SUCCESS](state, { role }) {
		state.role = role;
	},

	[types.FETCH_USER_FAILURE](state) {
		state.token = null;
		if (!kc) {
			localStorage.removeItem('token');
			VueCookies.remove('token');
		} else {
			kc.removeForKey((val) => token = val, console.error, 'token', 'ios')
		}
	},

	[types.LOGOUT](state) {
		state.user = null;
		state.token = null;
		if (!kc) {
			localStorage.removeItem('token');
			VueCookies.remove('token');
		} else {
			kc.removeForKey((val) => token = val, console.error, 'token', 'ios')
		}
	},

	[types.UPDATE_USER](state, { user }) {
		state.user = user;
	}
};

// actions
export const actions = {
	saveToken({ commit }, payload) {
		commit(types.SAVE_TOKEN, payload);
	},

	async fetchUser({ commit }) {
		try {
			const { data } = await ApiGetAuthUser();
			commit(types.FETCH_USER_SUCCESS, { user: data.user });
			commit(types.FETCH_ROLE_SUCCESS, { role: data.role });

			if (
				['android', 'ios'].includes(process.env.VUE_APP_MODE) &&
				window.plugins
			) {
				window.plugins.OneSignal.sendTag('user_type', data.role.name);
				window.plugins.OneSignal.sendTag(
					'external_user_id',
					data.user.id
				);
			}

			return true;
		} catch (e) {
			commit(types.FETCH_USER_FAILURE);
			return false;
		}
	},

	updateUser({ commit }, payload) {
		commit(types.UPDATE_USER, payload);
	},

	async logout({ commit }) {
		commit(types.LOGOUT);
	},

	async fetchOauthUrl(ctx, { provider }) {
		const { data } = await axios.post(`/api/oauth/${provider}`);
		return data.url;
	}
};
