import store from '@/store';

export default async (to, from, next) => {
	if (!store.getters['auth/check'] && store.getters['auth/token']) {
		try {
			await store.dispatch('auth/fetchUser');
			let role = store.getters['auth/role'];
			if (
				role.name == 'livreur' &&
				!['user-commands', 'user-my-commands', 'user-history'].includes(
					to.name
				)
			) {
				next({ name: 'user-commands' });
			}
		} catch (e) {
			console.warn("couldn't fetch the authenticated user", e);
		}
	}

	// if (
	// 	!store.getters['delivery.auth/check'] &&
	// 	store.getters['delivery.auth/token']
	// ) {
	// 	try {
	// 		await store.dispatch('delivery.auth/fetchUser');
	// 	} catch (e) {
	// 		console.warn("couldn't fetch the authenticated delivery user")
	// 	}
	// }

	next();
};
